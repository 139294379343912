import './bootstrap';
import '../css/app.css';

// axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');


import { createApp, h} from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { createI18n } from 'vue-i18n';
import { useFavicon } from '@vueuse/core';
import { USER_PERMISSIONS } from './constants';
import VueSweetalert2 from 'vue-sweetalert2';
import '@vuepic/vue-datepicker/dist/main.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import "qalendar/dist/style.css";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { loadLocaleMessages } from './dynamicI18n';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import AppHead from "@/Components/AppHead.vue";
import { createPinia } from 'pinia';


//import en from '../lang/i18n/en.json' // Import the English JSON file
//import de from '../lang/i18n/de.json'
//import fr from '../lang/i18n/fr.json' // Import the French JSON file
//import es from '../lang/i18n/es.json'
//import pt from '../lang/i18n/pt.json'
//import it from '../lang/i18n/it.json'
//import el from '../lang/i18n/el.json'
//import nl from '../lang/i18n/nl.json'
//import ro from '../lang/i18n/ro.json'

const defaultLocale = localStorage.getItem('locale') || 'en';
const i18n = new createI18n({
    locale: defaultLocale,
    messages: {},
    legacy: false,
    runtimeOnly: false
})
useFavicon('/favicon.ico');

function ucfirst(str) {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}


const ucfirstMixin = {
  methods: {
    ucfirst,
  },
};
const userPermissions = {
    data() {
        return {
            USER_PERMISSIONS: USER_PERMISSIONS,
        };
    },
};

const appName = import.meta.env.VITE_APP_NAME || 'Hospitaliti';
const pinia = createPinia();

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .mixin(ucfirstMixin)
            .mixin(userPermissions)
            .use(ZiggyVue)
			.use(i18n)
            .use(VueSweetalert2)
            .use(VueGoogleMaps, {
                load: {
                  key: import.meta.env.VITE_GOOGLE_API_KEY,
                  libraries: "places"
                },
              })
            .use(pinia)
            .component('AppHead', AppHead)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
loadLocaleMessages(i18n, defaultLocale);
