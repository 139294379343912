<script setup>
import { Head } from '@inertiajs/vue3';
import { onMounted } from 'vue';

const props = defineProps({ 
  title: String, 
  description: String, 
  keywords: String,
  jsonLD: Object
});

onMounted(() => {
  if (props.jsonLD) {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    const formattedJsonLD = props.jsonLD;
    script.text = formattedJsonLD;
    document.head.appendChild(script);
  }
});
</script>

<template>
  <Head>
    <title>{{ props.title ?? '' }}</title>
    <meta name="description" :content="props.description ? props.description : 'Hospitaliti'" />
    <meta property="keywords" :content="props.keywords ? props.keywords : 'Hospitaliti'" />
  </Head>
</template>